export const environment = {
     production: false,
     environment: 'DEV',
     server: 'UI_SERVER_DEV_AZURE',
     userManagement: 'https://usermanagement.dev1.gva.semgenome.com/',
     console: 'https://consoleapi.dev1.gva.semgenome.com/',
     dashboard: 'https://org-dashboard.dev1.gva.semgenome.com/',
     key: 'IW84Zmx2a21wKW1hdWw2MzlrMSMxcGZk',
     baseURL: 'dev1.gva.semgenome.com'
};
