<div *ngIf="showProgress" class="progressBar">
     <p-progressBar mode="indeterminate" [style]="{'height': '6px','background': 'rgba(0,0,0,0)'}" />
</div>
<div [ngClass]="showProgress ? 'disableClickOnPage' : 'enableClickOnPage'">
     @switch (pageLayoutService.layout$ | async) {
          @case (PageLayout.Authorized) {
               <app-authorized-layout [version]="version">
                    <router-outlet />
               </app-authorized-layout>
          }

          @case (PageLayout.Login) {
               <app-login-layout>
                    <router-outlet />
               </app-login-layout>
          }

          @default {
               <app-login-layout>
                    <router-outlet />
               </app-login-layout>
          }
     }
</div>
<app-toast></app-toast>