import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, catchError, Observable, Subject, takeUntil, tap } from 'rxjs';
import { UserLocalStorageData } from '../types-enums/types/authentication';
import { EncryptDecrypt } from '../utils/encrypt-decrypt';
import { MenuItem } from 'primeng/api';
import { ToastMsg } from '../types-enums/types/common';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
     providedIn: 'root'
})

export class AppSettingsService {
     public themeSelected = new BehaviorSubject<string>('lara-light-blue');
     public isLoading = new BehaviorSubject<boolean>(false);
     public breadcrumb = new BehaviorSubject<MenuItem[]>([]);
     public toast = new BehaviorSubject<ToastMsg>({ key: '', severity: '', summary: '', closable: true, forceClose: false, sticky: false });
     public version: string = '';
     private readonly destroySubject = new Subject();

     constructor(@Inject(DOCUMENT) private document: Document, private router: Router, private http: HttpClient) {
          router.events
               .pipe(takeUntil(this.destroySubject))
               .subscribe((val) => {
                    if (val instanceof NavigationEnd) {
                         if (val.url === '/') {
                              document.body.style.background = '#013357';
                         }
                    }
               });
          this.themeSelected.subscribe((res: string) => {
               if (res === 'lara-dark-blue') {
                    document.body.style.background = '#0d121d';
               } else {
                    document.body.style.background = '#f9fafb';
               }
          });
     }

     switchTheme(theme: string) {
          let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
          if (themeLink) {
               themeLink.href = theme + '.css';
          }
          this.themeSelected.next(theme);
     }

     getShortName() {
          const userData: UserLocalStorageData = JSON.parse(EncryptDecrypt.decryptWithKey(localStorage.getItem('gvaSystem')));
          return userData?.shortName ? userData.shortName : '';
     }

     getLoadingInfo() {
          return this.isLoading.value;
     }

     getVersion() {
          return this.version;
     }

     getAppVersion(): Observable<{version: string}> {
          var url = environment.console + 'version/';
          let params = new HttpParams();
          params = params.append('application_id', 2);
          return this.http.get<{version: string}>(url, {params}).pipe(
               tap((data:{version: string}) => {
                    this.version = data.version;
               }),
               catchError(error => {
                    throw error;
               })
          );
     }

}