<div class="layoutContainer" [ngClass]="sidebarVisible ? 'layoutStatic' : 'layoutStaticInactive'">
     <app-side-menu [sidebarVisible]="sidebarVisible" (emitSideBarStat)="toggleSideBar($event)" [version]="version"></app-side-menu>
     <div class="layoutContentWrapper">
          <div class="topNavigationContainer">
               <span class="breadCrumbConatiner">
                    <i class="pi pi-bars hamburgerMenu" (click)="toggleSideBar($event)"></i>
                    <app-breadcrumb></app-breadcrumb>
               </span>
               <app-profile-menu></app-profile-menu>
          </div>
          <div class="routeContainer">
               <ng-content></ng-content>
          </div>
     </div>
</div>