import { Routes } from '@angular/router';
import { setLayout } from './utils/layout-resolver';
import { PageLayout } from './types-enums/enums/page-layout';
import { authGuard } from './utils/auth.guard';

export const routes: Routes = [
     {
          path: '',
          loadComponent: () => import('./components/login/login.component').then((m) => m.LoginComponent),
          resolve: {
               layout: setLayout(PageLayout.Login)
          }
     },
     {
          path: 'dashboard',
          loadComponent: () => import('./components/dashboard/dashboard.component').then(mod => mod.DashboardComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'changePassword',
          loadComponent: () => import('./components/change-password/change-password.component').then(mod => mod.ChangePasswordComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'organisations',
          loadComponent: () => import('./components/organisations/organisations.component').then(mod => mod.OrganisationsComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'organisation-subscriptions',
          loadComponent: () => import('./components/organisation-subscriptions/organisation-subscriptions.component').then(mod => mod.OrganisationSubscriptionsComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'transaction-credit-correction',
          loadComponent: () => import('./components/transaction-credit-correction/transaction-credit-correction.component').then(mod => mod.TransactionCreditCorrectionComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'manage-users',
          loadComponent: () => import('./components/manage-users/manage-users.component').then(mod => mod.ManageUsersComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'onboard-users',
          loadComponent: () => import('./components/onboard-users/onboard-users.component').then(mod => mod.OnboardUsersComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'gva-roles',
          loadComponent: () => import('./components/gva-roles/gva-roles.component').then(mod => mod.GvaRolesComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'plans',
          loadComponent: () => import('./components/plans/plans.component').then(mod => mod.PlansComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'plan-roles',
          loadComponent: () => import('./components/plan-roles/plan-roles.component').then(mod => mod.PlanRolesComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'subscription-types',
          loadComponent: () => import('./components/subscription-types/subscription-types.component').then(mod => mod.SubscriptionTypesComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'subscriptions-master',
          loadComponent: () => import('./components/subscription-master/subscription-master.component').then(mod => mod.SubscriptionMasterComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'transaction-type-master',
          loadComponent: () => import('./components/transactiion-type-master/transactiion-type-master.component').then(mod => mod.TransactionTypeMasterComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'maintain-transaction-types',
          loadComponent: () => import('./components/maintain-transaction-types/maintain-transaction-types.component').then(mod => mod.MaintainTransactionTypesComponent),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: 'end-of-day-runs',
          loadComponent: () => import('./components/end-of-day-runs/end-of-day-runs.component').then(mod => mod.EndOfDayRuns),
          canActivate: [authGuard],
          resolve: {
               layout: setLayout(PageLayout.Authorized)
          }
     },
     {
          path: '**',
          loadComponent: () => import('./components/login/login.component').then(mod => mod.LoginComponent),
          resolve: {
               layout: setLayout(PageLayout.Login)
          }
     }
];