<p-sidebar #sidebarRef [(visible)]="sidebarVisible" position="left" [modal]="false" [showCloseIcon]="false" [closeOnEscape]="false">
     <ng-template pTemplate="headless">
          <div class="flex flex-column h-full">
               <div>
                    <div class="flex align-items-center justify-content-center px-4 pt-3 flex-shrink-0">
                         <span class="inline-flex align-items-center gap-2">
                              <a href="/dashboard">
                                   <img src="/assets/images/logo/semGenome.svg"
                                        alt="Sem Genome Gene - Variant Analysis">
                              </a>
                         </span>
                    </div>
                    <i class="pi pi-times-circle closeIcon" (click)="closeCallback($event)"></i>
                    <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
               </div>
               <div class="overflow-y-auto">
                    <ul class="list-none p-3 m-0">
                         <li>
                              <ul class="list-none p-0 m-0 overflow-hidden">
                                   <li (click)="goToPage('dashboard')">
                                        <a pRipple
                                             class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                             <i class="pi pi-home mr-2"></i>
                                             <span class="font-medium">DASHBOARD</span>
                                        </a>
                                   </li>
                                   <li>
                                        <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                             leaveToClass="hidden" leaveActiveClass="slideup"
                                             class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                             <i class="pi pi-building mr-2"></i>
                                             <span class="font-medium">SUBSCRIPTION MANAGEMENT</span>
                                             <i class="pi pi-chevron-down ml-auto"></i>
                                        </a>
                                        <ul
                                             class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                             <li (click)="goToPage('organisations')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-building-columns mr-2"></i>
                                                       <span class="font-medium">Manage organisations</span>
                                                  </a>
                                             </li>
                                             <li (click)="goToPage('organisation-subscriptions')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-dollar mr-2"></i>
                                                       <span class="font-medium">Assign Subscription & Plan</span>
                                                  </a>
                                             </li>
                                             <li (click)="goToPage('transaction-credit-correction')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-table mr-2"></i>
                                                       <span class="font-medium">Transaction/Credit Correction</span>
                                                  </a>
                                             </li>
                                        </ul>
                                   </li>
                                   <li>
                                        <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                             leaveToClass="hidden" leaveActiveClass="slideup"
                                             class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                             <i class="pi pi-users mr-2"></i>
                                             <span class="font-medium">USER MANAGEMENT</span>
                                             <i class="pi pi-chevron-down ml-auto"></i>
                                        </a>
                                        <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                             <li (click)="goToPage('manage-users')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-users mr-2"></i>
                                                       <span class="font-medium">Manage Users</span>
                                                  </a>
                                             </li>
                                             <li (click)="goToPage('onboard-users')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-user mr-2"></i>
                                                       <span class="font-medium">Onboard Users</span>
                                                  </a>
                                             </li>
                                        </ul>
                                   </li>
                                   <li>
                                        <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                             leaveToClass="hidden" leaveActiveClass="slideup"
                                             class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                             <i class="pi pi-cog mr-2"></i>
                                             <span class="font-medium">GVA MASTER SETTINGS</span>
                                             <i class="pi pi-chevron-down ml-auto"></i>
                                        </a>
                                        <ul
                                             class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                             <li (click)="goToPage('gva-roles')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-id-card mr-2"></i>
                                                       <span class="font-medium">GVA Roles</span>
                                                  </a>
                                             </li>
                                             <li (click)="goToPage('plans')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-clipboard mr-2"></i>
                                                       <span class="font-medium">Plans</span>
                                                  </a>
                                             </li>
                                             <li (click)="goToPage('plan-roles')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-file-check mr-2"></i>
                                                       <span class="font-medium">Plan Based Roles</span>
                                                  </a>
                                             </li>
                                             <li (click)="goToPage('subscription-types')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-receipt mr-2"></i>
                                                       <span class="font-medium">Subscription Types</span>
                                                  </a>
                                             </li>
                                             <li (click)="goToPage('subscriptions-master')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-indian-rupee mr-2"></i>
                                                       <span class="font-medium">Subscription Master</span>
                                                  </a>
                                             </li>
                                             <li (click)="goToPage('transaction-type-master')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-arrow-right-arrow-left mr-2"></i>
                                                       <span class="font-medium">Transaction Type Master</span>
                                                  </a>
                                             </li>
                                             <li (click)="goToPage('maintain-transaction-types')">
                                                  <a pRipple
                                                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                                       <i class="pi pi-pen-to-square mr-2"></i>
                                                       <span class="font-medium">Maintain Transaction Types</span>
                                                  </a>
                                             </li>
                                        </ul>
                                   </li>
                                   <li (click)="goToPage('end-of-day-runs')">
                                        <a pRipple
                                             class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                             <i class="pi pi-home mr-2"></i>
                                             <span class="font-medium">END OF DAY RUNS</span>
                                        </a>
                                   </li>
                              </ul>
                         </li>
                    </ul>
               </div>
               <div class="mt-auto">
                    <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
                    <div class="flex align-items-center justify-content-center mb-0">
                         © 2024 Semantic Web India Pvt Ltd.
                    </div>
                    <div class="flex align-items-center justify-content-center mb-2">
                         {{version}}
                    </div>
               </div>
          </div>
     </ng-template>
</p-sidebar>